<template>
  <PageContainer
    :title="title"
    :back="actions.back"
    :ellipsis="true"
    :submit="actions.submit"
  >
    <template v-slot:content>
      <!-- loader -->
      <div v-if="loading">
        <div class="options-skeleton segment-content">
          <div v-for="index in 2" :key="index">
            <Skeleton
              class="margin-y"
              width="174px"
              height="42px"
              borderRadius="8px"
            />
          </div>
        </div>
        <div class="page-segment">
          <div v-for="index in 3" :key="index">
            <Skeleton
              class="page-segment-content margin-bottom-sm"
              width="100%"
              height="160px"
              borderRadius="16px"
            />
          </div>
        </div>
      </div>

      <div class="segment" v-if="options && !loading">
        <div class="segment-content">
          <OptionSelector @changed="setOption" :options="options" />
        </div>
      </div>
      <!-- content -->
      <div class="page-segment" v-if="packagesByServices && packagesByServices.packages.length && !loading">
        <div
          class="page-segment-content margin-bottom-sm"
          v-for="(packageData, i) in packagesByServices.packages"
          :key="i"
        >
          <PackageCard
            v-selectable-container="hasSelectableAction"
            :specialist-id="$route.params.id"
            :service-name="packagesByServices.name"
            :package-data="packageData"
            :currency="packageData.product_packages[0].price.currency"
            :show-button="false"
            :can-open-detail="{ modal: true, view: false }"
            :icon-name="packageData.product_packages[0].icon_name"
            :show-addi="true"
            :is-clickable="specialist.is_public"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Skeleton,
  Specialists,
  SpecialistProfile,
  selectableContainer
} from '@seliaco/red-panda'
import OptionSelector from '@/components/misc/OptionSelector'
import PackageCard from '@/views/promos/packages/components/PackageCard'
import { PackageEvent } from '@/types/events'

export default {
  name: 'Packages',
  components: {
    PageContainer,
    Skeleton,
    OptionSelector,
    PackageCard
  },
  directives: { selectableContainer },
  created () {
    this.getSpecialistData(this.$route.params.id)
  },
  data () {
    return {
      specialist: null,
      loading: false,
      packages: [],
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        },
        submit: {
          text: this.$translations['specialist-page'].button,
          loading: false,
          icon: 'icon-timer-calendar-off',
          iconLeft: true,
          iconSize: 'xl',
          callback: () => this.goSchedule()
        }
      },
      options: null,
      selectedOption: null
    }
  },
  computed: {
    title () {
      return this.specialist?.user ? `${this.specialist?.user?.first_name} ${this.specialist?.user?.last_name}` : ''
    },
    packagesByServices () {
      if (!this.packages) {
        return null
      }

      // find packages by service selected
      return this.packages.find((e) => e.id === this.selectedOption)
    },
    hasSelectableAction () {
      return this.specialist.is_public ? { elementLevel: 1 } : null
    }
  },
  methods: {
    async getSpecialistData (id) {
      this.loading = true

      Specialists.getBySpecialistId(id)
        .then(async (response) => {
          this.specialist = response

          await SpecialistProfile.getPackages(this.specialist.id)
            .then((packageResponse) => {
              this.packages = packageResponse
              this.getPackages()
            })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.specialist
          })

          this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    goSchedule () {
      this.$router
        .push({
          name: 'Schedule',
          query: {
            specialistId: this.$route.params.id,
            back: this.$route.fullPath
          }
        })
    },
    getPackages () {
      // service selector options
      this.options = this.packages.map((item) => ({
        code: item.id,
        text: item.name
      }))

      this.selectedOption = this.options[0]
    },
    setOption (value) {
      this.selectedOption = value?.code
      this.callSegmentEvent(this.selectedOption)
    },
    callSegmentEvent (tab) {
      const body = {
        user: this.$store.getters['auth/user'],
        tab: tab,
        origin: 'Specialist profile'
      }
      PackageEvent.Package_Tab_Click(body)
    }
  },
  watch: {
    packages: {
      handler () {
        this.getPackages()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  border-bottom: 1px solid var(--gray-10)
.skeleton-container
  display: flex
  gap: 8px
.options-skeleton
  display: flex
  gap: 8px
.date
  display: flex
  gap: 8px
  .icon
    flex: none
    color: var(--purple)
    font-size: var(--xl)
    align-self: center
  .text
    text-transform: capitalize
    flex-grow: 1
    align-self: center
    color: var(--gray-80)
.datazero
  background: var(--gray-5)
  border-radius: 16px
</style>
